<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import GraspingRulesStores from './components/grasping_rules_stores.vue';

formCreate.component('graspingRulesStores', GraspingRulesStores);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'document_display_grabbing_form',
      bsDirectId: '',
      bsInvoiceId: '',
      bsDirectSystemCode: '',
      paramsRule: null,
      params: [],
      directSystemId: '',
      invoiceId: '',
      row: null,
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'bsDirectSystemCode') {
        // 直营体系
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.headers = { functionCode: 'acceptance_order_list' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
      }
      if (item.field === 'ruleId') {
        // 抓单规则
        item.restful = '/kms/kmsgrabrule/list';
        item.optionsKey = { label: 'ruleName', value: 'id' };
        item.restfulParamsGetValue = { bsDirectSystemId: 'bsDirectSystemId' };
        item.restfulParams = { orderType: 'ACCEPTANCE' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'ruleName',
        };
      }
      if (item.field === 'isAllStore') {
        item.options = [{
          label: '是',
          value: 'Y',
        }];
        item.on = {
          ...item.on,
          change: (e) => {
            if (e.length > 0) {
              this.hiddenFields(true, 'graspingRulesStores');
            } else {
              this.hiddenFields(false, 'graspingRulesStores');
            }
          },
        };
      }
      if (item.field === 'accountId') {
        item.restful = '/kms/kmstenantrydirectaccount/list';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'loginAcc', value: 'id' };
        item.restfulParamsGetValue = { bsDirectSystemId: 'bsDirectSystemId' };
        item.refresh = true;
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.basisRule = this.rule;
      const directSystemId = this.getRule('bsDirectSystemCode');
      const graspingRulesStores = this.getRule('graspingRulesStores');
      const invoiceId = this.getRule('ruleId');
      const loginAcc = this.getRule('accountId');
      directSystemId.on.change = (e) => {
        if (!e) {
          this.bsDirectSystemId = '';
          this.bsInvoiceId = '';
          this.bsDirectSystemCode = '';
          this.setValue({
            bsDirectSystemCode: '',
            bsDirectSystemName: '',
          });
          this.bsDirectSystemCode = '';
          graspingRulesStores.props.directSystemId = this.directSystemId;
          return;
        }
        const selectData = directSystemId.options.find((item) => item.bsDirectSystemCode === e);
        this.bsDirectSystemId = selectData.bsDirectSystemId;
        this.bsInvoiceId = selectData.bsDirectSystemId;
        this.directId = selectData.directId;
        this.bsDirectSystemCode = selectData.bsDirectSystemCode;
        this.setValue({
          bsDirectSystemId: selectData.bsDirectSystemId,
          bsDirectSystemName: selectData.bsDirectSystemName,
          directId: selectData.directId,
        });
        this.directSystemId = selectData.bsDirectSystemId;
        graspingRulesStores.props.directSystemId = this.directSystemId;
        graspingRulesStores.props.bsDirectSystemCode = this.bsDirectSystemCode;
      };
      invoiceId.on.change = (e) => {
        if (!e) {
          this.bsDirectSystemId = '';
          this.orderTypeId = '';
          this.bsDirectId = '';
          this.setValue({
            ruleId: '',
            orderType: '',
            fileDownloadWay: '',
            fileClassify: '',
            orderDownloadWay: '',
          });
          graspingRulesStores.props.invoiceId = '';
          return;
        }
        // 选中直营体系和单据类型后，获取详情数据
        this.fApi.disabled(true, ['directSystemId', 'invoiceId']);
        request.post('/kms/kmsgrabrule/query', {
          id: e,
        }).then((res) => {
          if (res.success) {
            if (res.result.isAllStore === 'Y') {
              this.hiddenFields(true, 'graspingRulesStores');
            }
            this.row = res.result;
            this.setValue({
              ...res.result,
              graspingRulesStores: res.result.ruleStoreList || [],
              isAllStore: res.result.isAllStore === 'Y' ? ['Y'] : [],
            });
            this.bsDirectId = res.result.bsDirectId;
            this.bsInvoiceId = res.result.bsInvoiceId;
            this.params = res.result.ruleParamList;
            this.directSystemId = res.result.directSystemId;
            this.invoiceId = res.result.orderType;
            this.bsDirectSystemId = res.result.bsDirectSystemId;
            graspingRulesStores.props.directSystemId = this.directSystemId;
            graspingRulesStores.props.invoiceId = this.invoiceId;
            if (this.formConfig.code === 'view') {
              graspingRulesStores.props.disabled = true;
            }
            this.$nextTick(() => {
              this.paramsRule = this.dealParamsRule(this.params);
            });
          }
        });
      };
      loginAcc.on.change = (e) => {
        const selectData = loginAcc.options.find((item) => item.id === e);
        this.setValue({
          accountId: selectData.id,
        });
      };
    },
    // 处理paramsRule
    dealParamsRule(params) {
      return params.map((v) => {
        const item = v;
        const ruleItem = {
          field: item.fieldEn,
          type: item.fieldClassify === 'SELECT' || item.fieldClassify === 'DATERANGE' ? 'select' : 'input',
          title: item.fieldCn,
          value: item.fieldValue,
          props: {
            clearable: true,
            disabled: this.formConfig.code === 'view',
            placeholder: item.fieldClassify === 'SELECT' || item.fieldClassify === 'DATERANGE' ? '请选择' : '请输入',
          },
          col: {
            md: { span: 8 },
          },
          validate: item.isRequired === 'YES' ? [
            {
              trigger: 'change',
              required: true,
              message: `${item.fieldCn}不能为空`,
            },
          ] : [],
          options: item.options && item.options.map((optionsItem) => ({
            label: optionsItem.optionName,
            value: optionsItem.optionValue,
          })),
        };
        this.fApi.append(ruleItem, 'title2');
        return ruleItem;
      });
    },
    // 开始时间change事件
    startDateChange(e) {
      const endDate = this.getRule('endDate');
      endDate.props = {
        ...endDate.props,
        pickerOptions: {
          selectableRange: `${e}:00 - 23:59:59`,
        },
      };
    },
    // 结束时间change事件
    endDateChange(e) {
      const startDate = this.getRule('startDate');
      startDate.props = {
        ...startDate.props,
        pickerOptions: {
          selectableRange: `00:00:00-${e}:00`,
        },
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      console.log(this.formData);
      if (formData.isAllStore && formData.isAllStore.length > 0) {
        formData.isAllStore = 'Y';
      } else {
        formData.isAllStore = 'N';
      }
      this.params = this.params.map((v, i) => {
        const item = v;
        item.value = formData[item.fieldEn];
        if (item.options && item.options.find((k) => k.optionValue === formData[item.fieldEn])) {
          item.optionName = item.options && item.options.find((k) => k.optionValue === formData[item.fieldEn]).optionName;
        }
        return item;
      });
      formData.ruleStoreList = formData.graspingRulesStores ? formData.graspingRulesStores.map((item) => ({ kmsStoreId: item.id })) : [];
      formData.ruleParamList = this.params.map((item) => ({
        ...item,
        fieldValue: item.value,
      }));

      if (formData) {
        const url = '/kms/kmsgrabrule/manualGrab';
        const params = { ...this.row, ...formData, orderType: 'ACCEPTANCE' };

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
