<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import formAdd from './formAdd.vue';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('acceptance_order_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.post('/kms/kmsacceptanceform/query', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
          formAdd: res.result.acceptanceGoodsVos,
        });
        this.row = res.result;
      });
    }
  },
};
</script>
