<script>
import TablePage from '@/found/components/table_page';

export default {
  name: 'table-component',
  extends: TablePage,
  props: {
    value: Array,
  },
  watch: {
    value(val) {
      this.tableData = val;
    },
  },
  data() {
    return {

    };
  },
  async created() {
    await this.getConfigList('acceptance_order_detail_product_list');
  },
};
</script>
